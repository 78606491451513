<template>
  <v-row>
    <v-col cols="12">
      <v-container>
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <v-row>
          <v-col cols="12" md="5" lg="5">
            <v-card class="px-4 mb-3 rounded-lg" outlined>
              <v-card-title class="px-0">
                <div class="font-weight-bold fs-20">{{ applymentDetail.talent_apply_job_job.job_title }}</div>
                <v-spacer></v-spacer>
                <v-chip label class="font-weight-medium" style="background-color: #E1EFFE;color: #1E429F;" small>
                  {{ applymentDetail.talent_apply_job_status == "PENDING" ? "MENUNGGU" : "DITERIMA" }}
                </v-chip>

                <div class="d-flex"
                  :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column justify-start align-start ' : 'justify-space-between align-center '"
                  style="width: 100%;">
                  <div class="justify-center d-flex align-center fs-14" style="gap: 5px">
                    <img v-if="applymentDetail.talent_apply_job_job.job_company.company_logo_url"
                      :src="applymentDetail.talent_apply_job_job.job_company.company_logo_url" alt="logo"
                      class="mr-3 rounded-circle" style="object-fit: cover;" width="50" height="50">
                    <v-avatar color="primary" class="caption white--text font-weight-medium" size="20" v-else>
                      {{ initialName(applymentDetail.talent_apply_job_job.job_company.company_name) }}
                    </v-avatar>
                    <div class="text-gray-900 font-weight-bold fs-12">{{
                      applymentDetail.talent_apply_job_job.job_company.company_name }}</div>
                  </div>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="px-0">

                <v-row class="mb-3" no-gutters>
                  <v-col cols="12" class="mb-3 d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-calendar-month-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium">Dikirim pada {{
                      convertFullDateTime(applymentDetail.createdAt) }}</div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16" style="rotate: 90deg;">mdi-tag-outline</v-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs"
                          v-if="applymentDetail.talent_apply_job_job.job_salary_confidential"
                          class="text-gray-900 fs-12 font-weight-medium text-truncate">{{
                            applymentDetail.talent_apply_job_job.job_salary }}</div>
                        <div v-else v-on="on" v-bind="attrs"
                          class="text-gray-900 fs-12 font-weight-medium text-truncate">{{
                            applymentDetail.talent_apply_job_job.job_salary_min | pricing
                          }} {{ " - " }} {{ applymentDetail.talent_apply_job_job.job_salary_max | pricing }}</div>
                      </template>
                      <div v-if="applymentDetail.talent_apply_job_job.job_salary_confidential">{{
                        applymentDetail.talent_apply_job_job.job_salary }}</div>
                      <div v-else>
                        <div v-if="applymentDetail.talent_apply_job_job.job_salary_max === 0">{{
                          applymentDetail.talent_apply_job_job.job_salary_min | pricing }}</div>
                        <div v-else>{{ applymentDetail.talent_apply_job_job.job_salary_min | pricing }} {{ " - " }} {{
                          applymentDetail.talent_apply_job_job.job_salary_max
                          |
                          pricing }}</div>
                      </div>
                    </v-tooltip>
                    <!-- <div v-if="applymentDetail.talent_apply_job_job.job_salary_confidential"
                      class="text-gray-900 fs-12 font-weight-medium text-truncate">{{
                      applymentDetail.talent_apply_job_job.job_salary }}</div>
                    <div v-else class="text-gray-900 fs-12 font-weight-medium text-truncate">{{
                      applymentDetail.talent_apply_job_job.job_salary_min | pricing }} {{ " - " }} {{
                      applymentDetail.talent_apply_job_job.job_salary_max | pricing }}</div> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="px-4 mb-3 rounded-lg" outlined>
              <v-card-title class="fs-16" :class="'px-0'">
                Tahapan Lamaran Pekerjaan
              </v-card-title>
              <v-card-text class="px-0">
                <p>Silahkan cek secara berkala untuk melihat update terbaru dari lamaran yang anda kirimkan sebelumnya
                </p>

                <!-- <div class="d-flex justify-space-between align-center">
                  <div class="font-weight-medium">
                    <div
                      class="text-blue-700 pa-0 font-weight-medium">
                      <v-icon size="18" class="mr-1" color="primary">mdi-check-decagram</v-icon>
                      Menunggu Review
                    </div>
                  </div>
                  <div class="font-weight-medium" style="color: #E5E7EB">/</div>
                  <div 
                    v-if="applymentDetail.talent_apply_job_status == 'PENDING'"
                    class="font-weight-medium" 
                    style="color: #6B7280;">
                    Pengumuman Lamaran
                  </div>
                  <div v-else class="font-weight-medium">
                    <div
                      class="text-blue-700 pa-0 font-weight-medium">
                      <v-icon size="18" class="mr-1" color="primary">mdi-check-decagram</v-icon>
                      Pengumuman Lamaran
                    </div>
                  </div>
                </div> -->
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" lg="7">

            <p class="fs-18 font-weight-bold">Tracking Lamaran Pekerjaan</p>

            <v-card flat class="pt-0 mt-0">
              <v-card-text class="px-0 py-0">
                <div class="d-flex flex-column align-start">
                  <div v-for="(timeline, i) in applymentDetail.talent_apply_job_histories" :key="i">

                    <div class="d-flex align-start">
                      <div>
                        <v-icon size="25" color="primary">
                          mdi-circle
                        </v-icon>
                        <div class="my-1 ml-3" style="width: 1px; height: 50px; background-color: #E5E7EB;">
                          <!-- v-if="i != interview_timeline.length - 1" -->
                        </div>
                      </div>
                      <div class="pl-2 ">
                        <div class="justify-center d-flex align-center fs-14 font-weight-regular "
                          style="gap: 8px; color: #111928;">
                          <div class="text-gray-700">{{ convertDateTime(timeline.createdAt) }}</div>
                          <div class="text-gray-900">{{ timeline.talent_apply_job_history_description }}</div>
                        </div>
                        <div
                          :style="`background: ${colorBackgroundStatus(timeline.talent_apply_job_history_status)}; width: fit-content;`"
                          class="px-3 py-1 mt-2 rounded-sm pa-0 font-weight-medium"
                          :class="`${colorTextStatus(timeline.talent_apply_job_history_status)}--text`">
                          <v-icon size="18" class="mr-1"
                            :color="colorTextStatus(timeline.talent_apply_job_history_status)">mdi-check-decagram</v-icon>
                          {{ convertStatus(timeline.talent_apply_job_history_status) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>

      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import moment from 'moment';

export default {
  name: "ApplymentDetail",
  data: () => ({
    breadcrumbs: [
      { text: "Lamaran", href: "/application" },
      { text: "Detail Lamaran", disabled: true },
    ],
    applymentDetail: {},
    job_detail: {
      job_title: "Frontend Developer",
      job_company: {
        company_logo_url: "",
        company_name: "Solutionlabs Grup"
      },
      job_salary: "Rp. 5.000.000 - Rp. 10.000.000",
    },
    interview_timeline: [
      {
        createdAt: "2024-07-10T12:24:17.124Z",
        updatedAt: "2024-07-10T12:24:17.124Z",
        deletedAt: null,
        talent_apply_job_history_id: "a781092a-8cdb-4cbd-978b-116fc4d4afb3",
        talent_apply_job_history_status: "PENDING",
        talent_apply_job_history_description: "Talent mengajukan lamaran",
        __entity: "TalentApplyJobHistory"
      },
      {
        createdAt: "2024-07-10T12:24:17.124Z",
        updatedAt: "2024-07-10T12:24:17.124Z",
        deletedAt: null,
        talent_apply_job_history_id: "a781092a-8cdb-4cbd-978b-116fc4d4afb3",
        talent_apply_job_history_status: "REVIEW",
        talent_apply_job_history_description: "Talent mengajukan lamaran",
        __entity: "TalentApplyJobHistory"
      },
      {
        createdAt: "2024-07-10T12:24:17.124Z",
        updatedAt: "2024-07-10T12:24:17.124Z",
        deletedAt: null,
        talent_apply_job_history_id: "a781092a-8cdb-4cbd-978b-116fc4d4afb3",
        talent_apply_job_history_status: "APPROVED",
        talent_apply_job_history_description: "Talent mengajukan lamaran",
        __entity: "TalentApplyJobHistory"
      },
      {
        createdAt: "2024-07-10T12:24:17.124Z",
        updatedAt: "2024-07-10T12:24:17.124Z",
        deletedAt: null,
        talent_apply_job_history_id: "a781092a-8cdb-4cbd-978b-116fc4d4afb3",
        talent_apply_job_history_status: "REJECTED",
        talent_apply_job_history_description: "Talent mengajukan lamaran",
        __entity: "TalentApplyJobHistory"
      },
    ]
  }),
  computed: {
    colorTextStatus() {
      return (status) => {
        switch (status) {
          case "PENDING":
            return "primary";
          case "REVIEW":
            return "warning";
          case "APPROVED":
            return "success";
          case "REJECTED":
            return "error";
          default:
            break;
        }
      }
    },
    colorBackgroundStatus() {
      return (status) => {
        switch (status) {
          case "PENDING":
            return "#E1EFFE";
          case "REVIEW":
            return "#FFFAE6";
          case "APPROVED":
            return "#F0FFF4";
          case "REJECTED":
            return "#FEF2F2";
          default:
            break;
        }
      }
    },
    initialName() {
      return (company_name) => {
        let firstAlias = "";
        let secondAlias = "";
        let name = company_name;
        let splitname = name.split(" ");

        if (splitname[0]) {
          firstAlias = splitname[0].substring(0, 1);
        }

        if (splitname[1]) {
          secondAlias = splitname[1].substring(0, 1);
        }
        return firstAlias + secondAlias;
      }
    },
  },
  mounted() {
    this.fetchDetailApplymentList();
  },
  methods: {
    convertFullDateTime(date) {
      return moment(date).format("DD MMMM YYYY HH:mm");
    },
    convertDateTime(date) {
      return moment(date).format("DD MMM YYYY HH:mm");
    },

    convertStatus(status) {
      switch (status) {
        case "PENDING":
          return "Menunggu";
        case "REVIEW":
          return "Sedang Direview";
        case "APPROVED":
          return "Diterima";
        case "REJECTED":
          return "Ditolak";
        default:
          break;
      }
    },

    async fetchDetailApplymentList() {
      await this.axios.get(`${JOB_PORTAL_API.LOWONGAN.GET_LIST}`, {
        params: {
          page: 1,
          limit: 10,
          // search: this.$route.params.id,
          // searchBy: "talent_apply_job_id",
          // 'filter.talent_apply_job_id': this.$route.params.id,
        }
      })
        .then((response) => {
          this.applymentDetail = response.data.data.find((item) => item.talent_apply_job_id == this.$route.params.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>