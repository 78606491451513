<template>
  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
    <div class="font-weight-bold primary-text mb-4 text-h5 pl-4">{{ act.title }}</div>
    <v-card elevation="0" class="col-md-12 mx-4 rounded-lg" outlined>
      <div class="pa-4">
        <div class="pa-4">
          <p
            class="text-body-1 text-justify mb-4"
            v-html="this.act.theory.description"
          ></p>
          <template v-if="showMeetingLink">
            <template v-if="meetingSchedule.is_day">
              <div
                v-for="(item, index) in meetingSchedule.schedules"
                :key="index"
              >
                <p>
                  Hari {{ day(item.day) }},
                  <span v-for="(time, index2) in item.times" :key="index2">
                      <!-- v-if="
                        jamSekarang <= time.end_time &&
                        jamSekarang >= time.start_time
                      " -->
                    <span >
                      pukul {{ time.start_time }} -
                      {{ time.end_time }} WIB<br />
                    </span>
                  </span>
                </p>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(item, index) in meetingSchedule.schedules"
                :key="index"
              >
                <p>
                  Tanggal {{ date(item.date) }},
                  <template v-for="(time, index2) in item.times">
                    <span
                      v-if="
                        jamSekarang <= time.end_time &&
                        jamSekarang >= time.start_time
                      "
                      :key="index2"
                    >
                      pukul {{ time.start_time }} -
                      {{ time.end_time }} WIB<br />
                    </span>
                  </template>
                </p>
              </div>
            </template>

            <div class="py-4"></div>
            <p v-if="act.type == 'berdiskusi_daring'">Link Meeting :</p>
            <div class="my-2">
              <v-btn
                small
                color="primary"
                dark
                class="white px-2"
                :href="this.act.theory.meeting.link"
                target="_blank"
                @click="handleClickMeetingLink"
              >
                Gabung Sekarang
              </v-btn>
            </div>

            <div class="mt-3">
              <div v-for="(item, index) in metaData" :key="'A' + index">
                <p>{{ item.title }} : {{ item.content }}</p>
              </div>
            </div>
          </template>

          <v-card elevation="0" class="py-3 rounded-lg">
            <iframe
              v-if="this.act.theory.link_embed.url != ''"
              :src="this.act.theory.link_embed.url"
              style="height: 500px; border-style: hidden"
            ></iframe>
          </v-card>

          <template v-if="isFinishSchedule">
            <div v-if="act.type == 'berdiskusi_daring'" class="my-4">
              Segera klik "Selesaikan Aktivitas" setelah jadwal live webinarmu
              berakhir!:
            </div>
            <div v-else-if="act.type == 'berdiskusi_luring'" class="my-2">
              Segera klik "Selesaikan Aktivitas"!:
            </div>
            <v-btn
              depressed
              small
              class="px-2 rounded-lg"
              color="primary"
              :loading="loading"
              @click="handleClickFinish()"
            >
              Selesaikan Aktivitas
            </v-btn>
          </template>
        </div>
      </div>
    </v-card>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    loading: Boolean,
    act: { type: Object, default: () => ({}) },
    detail_topic: Object,
  },
  computed: {
    finishSchedule() {
      if (!this.act.theory.meta_data) return {};
      const finishSchedule = this.act.theory.meta_data.find(
        (v) => v.title == "finish_activity"
      );
      if (finishSchedule && finishSchedule.content)
        return JSON.parse(finishSchedule.content);
      return {};
    },
    metaData() {
      if (!this.act.theory.meta_data) return {};
      return this.act.theory.meta_data.filter(
        (v) => v.title != "finish_activity"
      );
    },
    timeIsIt() {
      const theDate = new Date();
      return {
        date: theDate.getDate().toString().padStart(2, 0),
        month: (theDate.getMonth() + 1).toString().padStart(2, 0),
        year: theDate.getFullYear(),
        hour: theDate.getHours().toString().padStart(2, 0),
        minute: theDate.getMinutes().toString().padStart(2, 0),
      };
    },
    jamSekarang() {
      return `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;
    },
    tanggalSekarang() {
      return `${this.timeIsIt.year}-${this.timeIsIt.month}-${this.timeIsIt.date}`;
    },
    showMeetingLink() {
      const config = this.act?.theory?.meeting;
      // nek disembunyikan berarti muncul nek pas sesuai jadwal
      if (config && config.is_must_participate) {
        const theDate = new Date();
        const days = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        const tanggal = this.tanggalSekarang;
        // hs = hari sekarang
        const hs = theDate.getDay();
        // jbs = jam berapa sekarang
        const jbs = this.jamSekarang;
        switch (config.is_day) {
          case false:
            return config.dates.some((val) => {
              if (tanggal == val.date.slice(0, 10)) {
                return val.times.some((val2) => {
                  // console.log(jbs, val2.start_time, val2.end_time);
                  return jbs >= val2.start_time && jbs <= val2.end_time;
                });
              }
            });
          case true:
            return config.days.reduce((car, cur) => {
              const dayIndex = days.findIndex((v) => v == cur.day);
              if (dayIndex == hs)
                return (
                  car ||
                  cur.times.reduce((car2, cur2) => {
                    return (
                      car2 || (jbs >= cur2.start_time && jbs <= cur2.end_time)
                    );
                  }, false)
                );
              return car;
            }, false);
          default:
            return false;
        }
      }
      // nek gak disembunyikan berarti muncul terus
      // nek link e kosong berarti ra muncul
      return true;
    },
    isFinishSchedule() {
      if (!this.finishSchedule) return true;
      const theDate = new Date();
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const daySchedule = this.finishSchedule.days || [];
      const dateSchedule = this.finishSchedule.dates || [];
      const tanggal = this.tanggalSekarang;
      const time = `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;
      let in_time = false;

      console.log("this.finishSchedule.is_day", typeof this.finishSchedule.is_day);
      

      switch (this.finishSchedule.is_day) {
        case "1":
          in_time = daySchedule.some((val) => {
            const dayIndex = days.findIndex((v) => v == val.day);
            return (
              theDate.getDay() == dayIndex &&
              time >= val.start_time &&
              time <= val.end_time
            );
          });
          break;
        case "2":
          in_time = dateSchedule.some((val) => {
            return (
              val.date == tanggal &&
              time >= val.start_time &&
              time <= val.end_time
            );
          });
          break;
        default:
          break;
      }

      console.log("in_time", in_time);
      console.log("this.act?.theory?.meeting?.is_clicked", this.act?.theory?.meeting?.is_clicked);
      console.log("this.act.is_complete", this.act.is_complete);
      console.log("this.act.end_test_at", this.act.end_test_at);

      return (
        in_time &&
        this.act?.theory?.meeting?.is_clicked &&
        !this.act.is_complete
        // && !this.act.end_test_at
      );
    },
    meetingSchedule() {
      if (!this.act.theory) return [];
      const is_day = this.act.theory.meeting.is_day;
      if (is_day) return { is_day, schedules: this.act.theory.meeting.days };
      return { is_day, schedules: this.act.theory.meeting.dates };
    },
  },
  methods: {
    handleClickMeetingLink() {
      this.$emit("click:link");
    },
    day(day) {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const hari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      return hari[days.findIndex((v) => v == day)];
    },
    date(date) {
      return date ? moment(date).locale("en-gb").format("L") : null;
    },
    handleClickFinish() {
      this.$emit("click:finish");
    },
  },
};
</script>
