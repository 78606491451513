<template>
  <v-row>
    <v-col class="pt-0 pt-sm-6">
      <v-container fluid class="px-4">
        <!-- :style="{
          maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
        }" -->

        <Snackbar ref="snackbar" />

        <v-row class="mb-6">
          <v-col cols="12" md="3" lg="3">
            <v-card outlined flat class="rounded-lg" style="border: 1px solid #E5E7EB">
              <v-card-title>
                Profile Saya
                <v-spacer></v-spacer>
                <v-btn @click="() => { this.$router.push('/account') }" color="#1F2A37"
                  style="border: 1px solid #E5E7EB" class="rounded-lg" x-small outlined>
                  Profile & Resume
                </v-btn>
              </v-card-title>
              <v-card-text class="black--text">
                <div class="justify-start mb-3 d-flex align-center">
                  <v-avatar v-if="user_sistem.talent_photo_url" size="63">
                    <v-img class="mx-auto" :src="user_sistem.talent_photo_url"></v-img>
                  </v-avatar>
                  <v-avatar v-else color="primary" size="63">
                    <span class="text-h5 white--text">{{ initialName }}</span>
                  </v-avatar>
                  <div class="ml-3">
                    <div class="subtitle-1 font-weight-bold">{{ user_fullname }}</div>
                    <v-chip :color="open_to_work ? '#DEF7EC' : '#E5E7EB'" label small
                      :style="open_to_work ? 'color: #03543F;' : ''" class="font-weight-medium">
                      {{ open_to_work ? "Siap Bekerja" : "Belum Siap Bekerja" }}
                    </v-chip>
                  </div>
                </div>


                <div class="justify-start mr-3 d-flex align-center" style="gap: 5px;">
                  <v-chip
                    v-for="(item, index) in $vuetify.breakpoint.name == 'xl' ? user_skills.slice(0, 6) : user_skills.slice(0, 3)"
                    :key="index" label x-small class="pa-3" color="#E5E7EB">
                    {{ item.talent_skill_name }}
                  </v-chip>
                  <v-tooltip bottom v-if="$vuetify.breakpoint.name != 'xl'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-on="on" v-bind="attrs" v-if="user_sistem.talent_skills.length >= 4" label x-small
                        class="pa-3" color="#E5E7EB">
                        {{ user_skills.length - 3 }}+
                      </v-chip>
                    </template>
                    <div v-for="(skill, index) in user_skills.slice(3, user_skills.length)"
                      class="text-center subtitle-2 font-weight-medium" :key="index">
                      {{ skill.talent_skill_name }}
                    </div>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-on="on" v-bind="attrs" v-if="user_sistem.talent_skills.length >= 6" label x-small
                        class="pa-3" color="#E5E7EB">
                        {{ user_skills.length - 6 }}+
                      </v-chip>
                    </template>
                    <div v-for="(skill, index) in user_skills.slice(6, user_skills.length)"
                      class="text-center subtitle-2 font-weight-medium" :key="index">
                      {{ skill.talent_skill_name }}
                    </div>
                  </v-tooltip>
                </div>

              </v-card-text>
              <v-card-title>
                Lamaran Saya
                <v-spacer></v-spacer>
                <v-btn color="#1F2A37" style="border: 1px solid #E5E7EB" class="rounded-lg" x-small outlined
                  @click="() => { $router.push('/application') }">
                  <!-- @click="showFeature()"> -->
                  Semua
                </v-btn>
              </v-card-title>
              <v-card-text class="black--text" style="overflow: auto;">
                <div style="height: 310px;">
                  <!-- <div v-if="jobsApplyment.length > 0" class="justify-center d-flex flex-column align-center">
                    <div v-for="(item, index) in jobsApplyment" :key="index" class="mb-3">
                    </div>
                  </div> -->
                  <v-row v-if="jobsApplyment.length > 0">
                    <v-col v-for="(item, index) in jobsApplyment" :key="index" cols="12">
                      <v-card flat color="#F9FAFB" class="px-0 rounded-lg" @click="goToDetailApplyment(item)">
                        <v-card-title class="pb-2 font-weight-bold">
                          <div class="text-truncate subtitle-1 font-weight-bold" style="width: 130px;">{{
                            item.talent_apply_job_job.job_title }}</div>
                          <v-spacer></v-spacer>
                          <v-chip color="#1F2A37" style="background: #E5E7EB;border: 1px solid #E5E7EB" label small>
                            {{ convertStatus(item.talent_apply_job_status) }}
                          </v-chip>
                        </v-card-title>
                        <v-card-text class="black--text">
                          <div class="mb-1 font-weight-regular text-body-2">{{
                            item.talent_apply_job_job.job_company.company_name }}</div>
                          <div class="font-weight-medium caption">Dikirim pada {{ convertCreatedAt(item.createdAt) }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <div v-else style="height: 310px;" class="justify-center text-center d-flex flex-column align-center">
                    <img class="mx-auto"
                      :src="require('@/assets/images/illustrations/illustration-empty-applyment.svg')"
                      alt="illustrasi data kosong">
                    <div class="text-center">Belum Ada Kirim Lamaran</div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="9" lg="9" :order="$vuetify.breakpoint.name == 'xs' ? 'first' : 'last'">

            <p class="text-h6 font-weight-medium">Halo, {{ user_fullname }}</p>

            <v-row>
              <v-col v-for="(item, index) in summaryCard" :key="index" cols="6" md="3" lg="3">
                <v-card style="border: 1px solid #E5E7EB" flat class="rounded-lg">
                  <v-card-text class="justify-start d-flex align-center"
                    :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column text-center' : ''">
                    <div class="mr-2">
                      <img width="40" :src="item.icon" alt="icon" class="rounded-lg">
                    </div>
                    <div>
                      <div class="font-weight-medium text-uppercase caption"
                        :class="$vuetify.breakpoint.name == 'xs' ? ' my-2' : ''">{{ item.title }}</div>
                      <div class="text-h5 font-weight-bold black--text">{{ item.value }}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>


            <div class="my-6 d-flex justify-space-between align-center">
              <div class="text-h6 font-weight-medium">Bootcamp & TeFa Saya</div>
              <v-btn color="#1F2A37" style="border: 1px solid #E5E7EB" class="rounded-lg" x-small outlined
                @click="() => { this.$router.push('/class') }">
                Semua
              </v-btn>
            </div>

            <v-card style="border: 1px solid #E5E7EB" flat class="rounded-lg pa-0" v-if="myClass.length > 0">
              <v-card-text v-for="(item, index) in myClass" :key="index" class="cursor-pointer pa-0"
                @click="goDetailClass(item)">
                <div class="pa-5 d-flex align-start" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : ''">
                  <div class="justify-start d-flex align-start">
                    <div :class="$vuetify.breakpoint.name == 'xs' ? 'd-flex flex-column justify-space-between' : ''"
                      :style="$vuetify.breakpoint.name == 'xs' ? 'height: 130px' : ''">
                      <v-img width="150" height="70" class="rounded-lg"
                        :src="item.program.program_information.cover.url" alt="cover class">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-btn v-if="$vuetify.breakpoint.name == 'xs'" class="mt-2 rounded-lg"
                        :color="item.results.progress == 100 ? '#03543F' : '#1F2A37'"
                        :style="item.results.progress == 100 ? 'background: #DEF7EC;border: 1px solid #DEF7EC;' : 'background: #E5E7EB;border: 1px solid #E5E7EB'"
                        text x-small>
                        Progress {{ item.results.progress }}%
                      </v-btn>

                    </div>
                    <div class="ml-3">
                      <div class="subtitle-1 font-weight-bold black--text">{{ item.program.title }}</div>
                      <div class="d-flex caption font-weight-medium"
                        :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column mt-2' : 'align-center'"
                        style="gap: 1rem;">
                        <div class="d-flex align-center">
                          <img :src="require('@/assets/images/icon/grid-outline.svg')" alt="icon grid" class="mr-2">
                          <div>{{ item.program.learning_targets.studies.title }}</div>
                        </div>
                        <div class="d-flex align-center">
                          <img :src="require('@/assets/images/icon/user-headset-outline.svg')" alt="icon headset"
                            class="mr-2">
                          <div>By Sistem TEFA</div>
                        </div>
                        <div class="d-flex align-center">
                          <img :src="require('@/assets/images/icon/chart-line-up-outline.svg')" alt="icon chart line"
                            class="mr-2">
                          <div>{{ item.results.total_activity }} Aktivitas</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <v-spacer></v-spacer>
                  <v-btn v-if="$vuetify.breakpoint.name != 'xs'" class="rounded-lg"
                    :color="item.results.progress == 100 ? '#03543F' : '#1F2A37'"
                    :style="item.results.progress == 100 ? 'background: #DEF7EC;border: 1px solid #DEF7EC' : 'background: #E5E7EB;border: 1px solid #E5E7EB'"
                    text x-small>
                    Progress {{ item.results.progress }}%
                  </v-btn>
                </div>
                <v-divider v-if="index != myClass.length - 1"></v-divider>
              </v-card-text>
            </v-card>
            <v-card style="border: 1px solid #E5E7EB" flat class="rounded-lg pa-0" v-else>
              <v-card-text style="height: 300px;" class="justify-center text-center d-flex align-center">
                <div>
                  <img class="mx-auto" :src="require('@/assets/images/illustrations/illustration-empty-class.svg')"
                    alt="illustrasi data kosong">
                  <div class="text-center">Belum Mengikuti Bootcamp</div>
                </div>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>

        <div class="my-6 d-flex justify-space-between align-center">
          <div class="text-h6">Rekomendasi Lowongan Pekerjaan</div>
          <v-btn color="#1F2A37" style="border: 1px solid #E5E7EB" class="rounded-lg" x-small outlined
            @click="() => { $router.push('/jobs') }">
            <!-- @click="showFeature()"> -->
            Semua
          </v-btn>
        </div>

        <v-row v-if="jobsRecomendation.length > 0">
          <v-col cols="12" md="4" lg="4" v-for="(job, index) in jobsRecomendation" :key="index"
            style="overflow: auto;width: 100%;">
            <v-card class="px-4 mb-3 rounded-lg" outlined @click="goToDetailJob(job)">
              <v-card-title class="px-0">
                <div class="text-gray-900 font-weight-bold fs-16">{{ job.job_title }}</div>
                <v-spacer></v-spacer>
                <v-btn class="text-none" elevation="0" icon x-small text @click="(e) => {
                  e.stopPropagation();
                  sharejobs(job);
                }">
                  <v-icon size="16" color="black">ri-share-line</v-icon>
                </v-btn>
                <v-btn class="text-none" elevation="0" icon x-small text @click="(e) => {
                  e.stopPropagation();
                  saveToBookmark(job)
                }">
                  <v-icon size="16" :color="job.job_talent_bookmarks.length > 0 ? 'primary' : 'black'"> {{
                    job.job_talent_bookmarks.length > 0 ? 'ri-bookmark-fill' : 'ri-bookmark-line' }}</v-icon>
                </v-btn>

                <div class="d-flex"
                  :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column justify-start align-start ' : 'justify-space-between align-center '"
                  style="width: 100%;">
                  <div class="justify-center d-flex align-center" style="gap: 5px">
                    <img v-if="job.job_company.company_logo_url" :src="job.job_company.company_logo_url" alt="logo"
                      class="mr-3 rounded-circle" style="object-fit: cover;" width="50" height="50">
                    <v-avatar color="primary" class="caption white--text font-weight-medium" size="20" v-else>
                      {{ initialName(job.job_company.company_name) }}
                    </v-avatar>
                    <div class="text-gray-900 font-weight-bold fs-12">{{ job.job_company.company_name }}</div>
                  </div>
                  <!-- <div v-if="$vuetify.breakpoint.name != 'xs'" class="fs-12 font-weight-medium green--text">
                    {{ convertCreatedAt(job.createdAt) }}
                  </div> -->
                </div>
                <div class="justify-start d-flex align-center"
                  :class="$vuetify.breakpoint.name == 'xs' ? 'fs-10' : 'fs-14'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12.0002 14C11.2091 14 10.4357 13.7654 9.77795 13.3259C9.12015 12.8863 8.60746 12.2616 8.30471 11.5307C8.00196 10.7998 7.92275 9.99554 8.07709 9.21961C8.23143 8.44369 8.61239 7.73096 9.1718 7.17155C9.73121 6.61214 10.4439 6.23117 11.2199 6.07683C11.9958 5.92249 12.8001 6.0017 13.531 6.30445C14.2619 6.60721 14.8866 7.11989 15.3261 7.77769C15.7656 8.43549 16.0002 9.20885 16.0002 9.99997C16.0002 11.0608 15.5788 12.0783 14.8287 12.8284C14.0785 13.5785 13.0611 14 12.0002 14ZM12.0002 7.99997C11.6047 7.99997 11.218 8.11727 10.8891 8.33703C10.5602 8.5568 10.3038 8.86915 10.1525 9.23461C10.0011 9.60006 9.96149 10.0022 10.0387 10.3902C10.1158 10.7781 10.3063 11.1345 10.586 11.4142C10.8657 11.6939 11.2221 11.8844 11.61 11.9615C11.998 12.0387 12.4001 11.9991 12.7656 11.8477C13.131 11.6964 13.4434 11.44 13.6632 11.1111C13.8829 10.7822 14.0002 10.3955 14.0002 9.99997C14.0002 9.46954 13.7895 8.96083 13.4144 8.58576C13.0394 8.21069 12.5307 7.99997 12.0002 7.99997Z"
                      fill="#6B7280" />
                    <path
                      d="M12.0002 22C11.8526 21.9999 11.7068 21.9671 11.5733 21.904C11.4398 21.8409 11.322 21.749 11.2282 21.635L5.98323 15.261C5.88323 15.149 5.76923 15.009 5.65723 14.861L5.53723 14.715C5.49065 14.6585 5.45041 14.5972 5.41723 14.532C4.59355 13.3376 4.11017 11.9418 4.01881 10.4939C3.92745 9.04595 4.23156 7.60043 4.89858 6.31204C5.5656 5.02366 6.57043 3.94091 7.80551 3.1797C9.04059 2.4185 10.4594 2.00748 11.9101 1.99065C13.3608 1.97382 14.7888 2.35181 16.0412 3.08416C17.2936 3.81652 18.3233 4.87566 19.02 6.14822C19.7168 7.42079 20.0543 8.85886 19.9966 10.3085C19.9388 11.7582 19.488 13.1649 18.6922 14.378C18.6596 14.4467 18.6186 14.5112 18.5702 14.57L18.4432 14.725C18.2645 14.9695 18.0715 15.2032 17.8652 15.425L12.7732 21.63C12.6799 21.7451 12.5621 21.838 12.4284 21.902C12.2947 21.966 12.1484 21.9994 12.0002 22ZM7.17523 13.562L7.22223 13.619C7.32223 13.754 7.41523 13.861 7.50723 13.965L12.0002 19.425L16.3582 14.117C16.5373 13.9241 16.7043 13.7204 16.8582 13.507L16.9332 13.416L16.9592 13.375C17.5778 12.468 17.9356 11.4088 17.9938 10.3125C18.0521 9.21626 17.8084 8.12505 17.2894 7.15769C16.7704 6.19034 15.9959 5.38391 15.0503 4.82622C14.1048 4.26853 13.0243 3.98096 11.9266 3.99481C10.8289 4.00866 9.75601 4.32341 8.8248 4.90478C7.89359 5.48615 7.13972 6.31186 6.64529 7.292C6.15086 8.27215 5.93481 9.36916 6.02067 10.4636C6.10652 11.558 6.491 12.6079 7.13223 13.499C7.14823 13.5203 7.16256 13.5413 7.17523 13.562Z"
                      fill="#6B7280" />
                  </svg>
                  <div class="text-gray-700 text-capitalize fs-12 font-weight-regular">{{ job.job_province.province_name
                    }}</div>
                </div>

                <!-- <div class="font-weight-bold fs-24">{{ job.job_title }}</div> -->
                <!-- <div v-if="$vuetify.breakpoint.name == 'xs'" class="fs-12 font-weight-medium green--text">
                  {{ convertCreatedAt(job.createdAt) }}
                </div> -->
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="px-0">

                <v-row class="mb-3" no-gutters>
                  <v-col cols="4" class="mb-3 d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-briefcase-variant-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium text-truncate">{{ job.job_category ?
                      job.job_category?.label : "-" }}</div>
                  </v-col>
                  <v-col cols="4" class="mb-3 d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-clock-time-four-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium text-truncate">{{ job.job_type_of_job ?
                      job.job_type_of_job.label : "-" }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-home-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium text-truncate">{{ job.job_type_of_workplace ?
                      job.job_type_of_workplace?.label : "-" }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-account-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium text-truncate">{{ job.job_type_of_experience ?
                      job.job_type_of_experience?.label : "-" }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-calendar-month-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium text-truncate">{{ currentDate(job.createdAt) }}
                    </div>
                  </v-col>
                  <v-col cols="4" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16" style="rotate: 90deg;">mdi-tag-outline</v-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs" v-if="job.job_salary_confidential"
                          class="text-gray-900 fs-12 font-weight-medium text-truncate">{{ job.job_salary }}</div>
                        <div v-else v-on="on" v-bind="attrs"
                          class="text-gray-900 fs-12 font-weight-medium text-truncate">
                          <div v-if="job.job_salary_max === 0">{{ job.job_salary_min | pricing }}</div>
                          <div v-else>{{ job.job_salary_min | pricing }} {{ " - " }} {{ job.job_salary_max |
                            pricing }}</div>
                        </div>
                      </template>
                      <div v-if="job.job_salary_confidential">{{ job.job_salary }}</div>
                      <div v-else>
                        <div v-if="job.job_salary_max === 0">{{ job.job_salary_min | pricing }}</div>
                        <div v-else>{{ job.job_salary_min | pricing }} {{ " - " }} {{ job.job_salary_max |
                          pricing }}</div>
                      </div>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <!-- <v-chip
                    v-for="tag in ['Fulltime', 'Remote']"
                    :key="tag"
                    label
                    small
                    color="#F3F4F6"
                    class="mr-1 text-gray-900 rounded-lg font-weight-medium"
                  >
                    {{ tag }}
                  </v-chip> -->

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card flat outlined>
              <v-card-text style="height: 300px;" class="justify-center text-center d-flex align-center black--text">
                <div class="justify-center d-flex align-center"
                  :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column' : 'flex-row'">
                  <img class="mx-auto" :class="$vuetify.breakpoint.name == 'xs' ? 'mb-4' : 'mr-4'"
                    :src="require('@/assets/images/illustrations/illustration-empty-jobs.svg')"
                    alt="illustrasi data kosong">
                  <div :class="$vuetify.breakpoint.name == 'xs' ? 'text-center' : 'text-left'">
                    <div>Lengkapi profilmu untuk mendapatkan rekomendasi lowongan</div>
                    <v-btn @click="() => { this.$router.push('/account') }" color="#1F2A37"
                      style="border: 1px solid #E5E7EB" class="mt-4 rounded-lg" x-small outlined>
                      Lengkapi Sekarang
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { LMS_API, JOB_PORTAL_API } from "@/constants/api"

import moment from "moment";
import { mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";


export default {
  name: "dashboard",
  components: {
    Snackbar
  },
  data() {
    return {
      open_to_work: false,
      summaryTotal: {
        pelatihan_aktif: 0,
        pelatihan_lulus: 0,
        sertifikat: 0,
        lamaran: 0,
      },

      summaryCard: [],
      myClass: [],
      jobsApplyment: [],
      jobsRecomendation: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),
    user_fullname() {
      return this.user_sistem.talent_first_name + ' ' + this.user_sistem.talent_last_name
    },
    user_skills() {
      return this.user_sistem.talent_skills
    },
    initialName() {
      let firstAlias = "";
      let secondAlias = "";
      let name = this.user_sistem ? this.user_fullname : "USER TESTING";
      let splitname = name.split(" ");

      if (splitname[0]) {
        firstAlias = splitname[0].substring(0, 1);
      }

      if (splitname[1]) {
        secondAlias = splitname[1].substring(0, 1);
      }
      return firstAlias + secondAlias;
    }
  },
  beforeMount() {
    localStorage.setItem('sistem_program_name', "")
    this.$store.commit("SET_PROGRAM_NAME", "");
  },
  mounted() {
    this.getMe()
    this.getSummarys().getProgramActive()
    this.getSummarys().getProgramCompleted()
    this.getSummarys().getProgramCertificate()
    this.getSummaryCard()
    this.getClass()
    this.getJobsRecomendation()
    this.getJobsApplyment()
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {

    currentDate(date) {
      return moment(date).fromNow()
    },

    convertCreatedAt(date) {
      return moment(date).format("dddd, DD MMMM YYYY HH:mm");
    },

    convertStatus(status) {
      switch (status) {
        case "PENDING":
          return "Menunggu";
        case "REVIEW":
          return "Sedang Direview";
        case "APPROVED":
          return "Diterima";
        case "REJECTED":
          return "Ditolak";
        default:
          break;
      }
    },

    showFeature() {
      this.$refs.snackbar.open("primary", "Fitur ini sedang dalam pengembangan developer");
    },

    // GET ME
    async getMe() {
      await this.axios.get(`${JOB_PORTAL_API.USER.GET_ME}`)
        .then((response => {
          let res = response.data
          this.open_to_work = res.open_to_work
        }))
    },

    // GET SUMMARY
    getSummarys() {
      let _self = this
      return {
        async getProgramActive() {
          await _self.axios
            .get(`${LMS_API.PROGRAM.SUB_TOTAL.PROGRAM_ACTIVE}`)
            .then((response) => {
              let res = response.data.data;
              if (response.status == 200) {
                _self.summaryTotal.pelatihan_aktif = res.total
              }
            });
        },
        async getProgramCompleted() {
          await _self.axios
            .get(`${LMS_API.PROGRAM.SUB_TOTAL.PROGRAM_COMPLETED}`)
            .then((response) => {
              let res = response.data.data;
              if (response.status == 200) {
                _self.summaryTotal.pelatihan_lulus = res.total
              }
            });
        },
        async getProgramCertificate() {
          await _self.axios
            .get(`${LMS_API.PROGRAM.SUB_TOTAL.PROGRAM_CERTIFICATE}`)
            .then((response) => {
              let res = response.data.data;
              if (response.status == 200) {
                _self.summaryTotal.sertifikat = res.total
              }
            });
        },
      }

    },

    // GET SUMMARY CARD
    async getSummaryCard() {

      await this.getSummarys().getProgramActive()
      await this.getSummarys().getProgramCompleted()
      await this.getSummarys().getProgramCertificate()

      this.summaryCard = [
        {
          title: "Pelatihan Aktif",
          icon: require("@/assets/images/icon/icon-pelatihan-aktif.svg"),
          color: "#1C64F2",
          color_medium: "#C3DDFD",
          color_soft: "#EBF5FF",
          value: this.summaryTotal.pelatihan_aktif,
        },
        {
          title: "Pelatihan Lulus",
          icon: require("@/assets/images/icon/icon-pelatihan-lulus.svg"),
          color: "#E3A008",
          color_medium: "#FDF6B2",
          color_soft: "#FDFDEA",
          value: this.summaryTotal.pelatihan_lulus,
        },
        {
          title: "Sertifikat",
          icon: require("@/assets/images/icon/icon-sertifikat.svg"),
          color: "#0E9F6E",
          color_medium: "#DEF7EC",
          color_soft: "#F3FAF7",
          value: this.summaryTotal.sertifikat,
        },
        {
          title: "Lamaran",
          icon: require("@/assets/images/icon/icon-lamaran.svg"),
          color: "#5850EC",
          color_medium: "#E5EDFF",
          color_soft: "#F0F5FF",
          value: this.summaryTotal.lamaran,
        }
      ]
    },

    // GET JOB APPLYMENT
    async getJobsApplyment() {
      await this.axios
        .get(`${JOB_PORTAL_API.LOWONGAN.GET_LIST}`, {
          params: {
            limit: 3,
          }
        })
        .then((response) => {
          let res = response.data;
          this.jobsApplyment = res.data;
        });
    },

    goToDetailJob(job) {
      this.$router.push({ name: "jobs-detail", params: { id: job.job_id } });
    },

    // GO TO DETAIL APPLYMENT
    goToDetailApplyment(applyment) {
      console.log(applyment);

      this.$router.push({ name: "applyment-detail", params: { id: applyment.talent_apply_job_id } });
    },

    // GET JOB RECOMENDATION
    async getJobsRecomendation() {
      await this.axios
        .get(`${JOB_PORTAL_API.JOBS.GET_RECOMMENDED}`, {
          params: {
            limit: 3,
            job_status: "OPEN",
          }
        })
        .then((response) => {
          let res = response.data;
          this.jobsRecomendation = res.data;
        });
    },

    async saveToBookmark(job) {
      let URL_API = `${JOB_PORTAL_API.LOWONGAN.BOOKMARK.CREATE(job.job_id)}`
      let STATUS = 'create'

      if (job.job_talent_bookmarks.length > 0) {
        URL_API = `${JOB_PORTAL_API.LOWONGAN.BOOKMARK.DELETE(job.job_id)}`
        STATUS = 'delete'
      }

      await this.axios.post(`${URL_API}`)
        .then(() => {

          this.$refs.snackbar.open('primary', `${STATUS == 'create' ? 'Berhasil menambahkan lowongan ke' : 'Berhasil menghapus lowongan dari'} bookmark`)

          this.getJobsRecomendation()
        }).catch(() => {
          this.$refs.snackbar.open('error', 'Gagal menambahkan lowongan ke bookmark')
        })
    },

    sharejobs(job) {
      // create copy text to clipboard
      navigator.clipboard.writeText(window.location.origin + '/jobs/' + job.job_id)

      this.$refs.snackbar.open('primary', 'Berhasil menyalin link lowongan')
    },

    // GET CLASS
    async getClass() {
      await this.axios
        .get(`${LMS_API.PROGRAM.LIST}`, {
          params: {
            limit: 3,
            is_from_subscribe: 0,
            sort: 'created_at',
            dir: 'desc'
          }
        })
        .then((response) => {
          let res = response.data.data;
          if (response.status == 200) {
            this.myClass = res.list;
          }
        });
    },

    // DETAIL CLASS
    goDetailClass(item) {
      if (item.is_lock) {
        return false
      }

      this.$store.commit('SET_PROGRAM_NAME', item.program.title)
      localStorage.setItem('sistem_program_name', item.program.title)

      if (item.program.flag != 'sertifikasi') {
        // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") > -1) {
          this.$router.push({
            name: "detail-class",
            params: { id: item.id },
          });
        } else {
          this.$router.push({ name: 'detail-class', params: { id: item.id } })
          // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
          //   this.$router.push({ name: 'detail-class-v2', params: { id: item.id } })
          // } else {
          //   this.$router.push({ name: 'detail-class-v2', params: { id: item.id } })
          // }
        }
      } else {
        this.$router.push({ name: 'checkpoint', params: { id: item.id } })
      }

    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
.background-dashboard-card {
  background: radial-gradient(280.92% 209.58% at 189.66% 86.23%, rgba(26, 186, 221, 0.48) 0%, rgba(129, 226, 248, 0.48) 38.29%, rgba(17, 32, 167, 0.48) 57.58%, rgba(10, 3, 49, 0.48) 82.81%), linear-gradient(90deg, #1A56DB 0%, #45B2FF 100%);
}
</style>