<template>
	<v-row :no-gutters="$vuetify.breakpoint.smAndUp">
		<div class="pl-4 mb-4 font-weight-bold primary-text text-h5">{{ act.title }}</div>
		<v-card elevation="0" style="height: 100vh; width: 100%">
    <div class="pl-4 mb-4" v-html="act.theory.description"></div>
      <!-- <iframe 
        :src="act.theory.file.url" 
        width="100%"
        height="100%"
        frameborder="0">
      </iframe> -->
			<!-- <vue-pdf-app :pdf="act.theory.file.url +'#toolbar=0'" :config="config"></vue-pdf-app> -->
      <div v-if="isLoading">
        <v-progress-linear
          indeterminate
          color="primary"
        ></v-progress-linear>
      </div>
      <vue-pdf-embed 
        annotationLayer textLayer 
        :source="act.theory.file.url" 
        style="height: 90vh; width: 100%; overflow-y: scroll"
        @rendered="handleDocumentRender"
      />
		</v-card>
	</v-row>
</template>

<script>
// import VuePdfApp from "vue-pdf-app";
// import "vue-pdf-app/dist/icons/main.css";

// import VuePdfEmbed from 'vue-pdf-embed'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
	components: {
		// VuePdfApp,
    'vue-pdf-embed': VuePdfEmbed
	},
	props: {
		act: Object
	},
	data() {
		return {
      isLoading: true,
			config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      },
		}
	},
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
    }
  }
}
</script>