<template>
  <v-row>
    <v-col cols="12">
      <v-container>

        <p class="font-weight-bold fs-16">Lamaran yang Anda kirim</p>

        <v-tabs :right="$vuetify.breakpoint.name == 'xs'">
          <v-tab @click="fetchApplymentList('')">Semua</v-tab>
          <v-tab @click="fetchApplymentList('PENDING')">Menunggu</v-tab>
          <v-tab @click="fetchApplymentList('REVIEW')">Sedang Direview</v-tab>
          <v-tab @click="fetchApplymentList('APPROVED')">Diterima</v-tab>
        </v-tabs>
        <v-divider></v-divider>

        <v-row v-if="applymentList.length > 0" class="mt-3">
          <v-col cols="12" md="4" lg="4" v-for="(job, i) in applymentList" :key="i">
            <v-card class="px-4 mb-3 rounded-lg" outlined @click="goDetailApplyment(job)">
              <v-card-title class="px-0">
                <div class="font-weight-bold fs-16">{{ job.talent_apply_job_job.job_title }}</div>
                <v-spacer></v-spacer>
                <v-chip label class="font-weight-medium fs-12" style="background-color: #E1EFFE;color: #1E429F;" small>
                  {{ job.talent_apply_job_status }}
                </v-chip>

                <div class="d-flex"
                  :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column justify-start align-start ' : 'justify-space-between align-center '"
                  style="width: 100%;">
                  <div class="justify-center d-flex align-center fs-14" style="gap: 5px">
                    <img v-if="job.talent_apply_job_job.job_company.company_logo_url"
                      :src="job.talent_apply_job_job.job_company.company_logo_url" alt="logo"
                      class="mr-3 rounded-circle" style="object-fit: cover;" width="50" height="50">
                    <v-avatar color="primary" class="caption white--text font-weight-medium" size="20" v-else>
                      {{ initialName(job.talent_apply_job_job.job_company.company_name) }}
                    </v-avatar>
                    <div class="text-gray-900 font-weight-bold fs-12">{{
                      job.talent_apply_job_job.job_company.company_name }}</div>
                  </div>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="px-0">

                <v-row class="mb-3" no-gutters>
                  <v-col cols="12" class="mb-3 d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16">mdi-calendar-month-outline</v-icon>
                    <div class="text-gray-900 fs-12 font-weight-medium">Dikirim pada {{ convertDatetime(job.createdAt)
                      }}</div>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <v-icon class="mr-2" color="#6B7280" size="16" style="rotate: 90deg;">mdi-tag-outline</v-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs" v-if="job.job_salary_confidential"
                          class="text-gray-900 fs-12 font-weight-medium text-truncate">{{
                            job.job_salary }}</div>
                        <div v-else v-on="on" v-bind="attrs"
                          class="text-gray-900 fs-12 font-weight-medium text-truncate">{{
                            job.job_salary_min | pricing
                          }} {{ " - " }} {{ job.job_salary_max | pricing }}</div>
                      </template>
                      <div v-if="job.job_salary_confidential">{{
                        job.job_salary }}</div>
                      <div v-else>
                        <div v-if="job.job_salary_max === 0">{{
                          job.job_salary_min | pricing }}</div>
                        <div v-else>{{ job.job_salary_min | pricing }} {{ " - " }} {{
                          job.job_salary_max
                          |
                          pricing }}</div>
                      </div>
                    </v-tooltip>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            <v-card class="rounded-lg" flat>
              <v-card-text>
                <div class="text-center">
                  <img src="@/assets/images/illustrations/illustration-empty-applyment.svg" alt="empty-job" width="200">
                  <p class="mt-5 text-gray-900 font-weight-medium fs-20">Anda belum mengirimkan lamaran pekerjaan</p>
                  <p class="text-gray-600 font-weight-regular fs-14">Cari pekerjaan yang sesuai dengan bakat Anda</p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { JOB_PORTAL_API } from '@/constants/api';

import moment from 'moment';

export default {
  name: "ApplymentList",
  data: () => ({
    applymentList: [],
  }),
  computed: {
    initialName() {
      return (company_name) => {
        let firstAlias = "";
        let secondAlias = "";
        let name = company_name;
        let splitname = name.split(" ");

        if (splitname[0]) {
          firstAlias = splitname[0].substring(0, 1);
        }

        if (splitname[1]) {
          secondAlias = splitname[1].substring(0, 1);
        }
        return firstAlias + secondAlias;
      }
    },
  },
  mounted() {
    this.fetchApplymentList();
  },
  methods: {
    convertDatetime(date) {
      return moment(date).format("DD MMMM YYYY HH:mm");
    },

    async fetchApplymentList(status_applyment) {

      let params = {
        page: 1,
        limit: 10,
      };

      if (status_applyment) {
        params['filter.talent_apply_job_status'] = status_applyment;
      }

      await this.axios.get(`${JOB_PORTAL_API.LOWONGAN.GET_LIST}`, { params })
        .then((response) => {
          this.applymentList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goDetailApplyment(job) {
      this.$router.push({
        name: "applyment-detail",
        params: {
          id: job.talent_apply_job_id,
        },
      });
    },
  }
}
</script>